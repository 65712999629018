<template>
  <svg
    role="img"
    aria-label="play icon"
    tabindex="-1"
    class="play-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 13"
  >
    <title>Play</title>
    <path
      d="M8.86874 5.35624C9.41769 5.75992 9.40976 6.58309 8.85312 6.97767L1.8333 11.9538C1.16738 12.4258 0.247917 11.9457 0.255776 11.13L0.352776 1.06308C0.360635 0.247403 1.28921 -0.217518 1.94592 0.265412L8.86874 5.35624Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.play-icon {
  height: 14px;
  width: 13px;
}
</style>
