<template>
  <svg
    role="img"
    aria-label="previous-10 icon"
    class="previous-10-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
  >
    <title>Previous 10</title>

    <path
      d="M0 1C0 0.44772 0.4477 0 1 0C1.55228 0 2 0.44772 2 1V5.41264C3.82524 3.34368 6.16788 1.76764 8.81284 0.86214C12.4853 -0.39514 16.4897 -0.27382 20.0793 1.20342C23.6689 2.68066 26.5987 5.413 28.3225 8.89098C28.5677 9.38582 28.3655 9.9858 27.8705 10.231C27.3757 10.4763 26.7757 10.274 26.5305 9.77912C25.0223 6.73608 22.4589 4.34544 19.3181 3.05292C16.1775 1.76042 12.6738 1.65428 9.46064 2.75432C6.63406 3.722 4.20206 5.56496 2.50758 8H9C9.55228 8 10 8.44772 10 9C10 9.55228 9.55228 10 9 10H1C0.4477 10 0 9.55228 0 9V1ZM10.004 15C10.004 14.6312 9.80102 14.2924 9.47586 14.1184C9.1507 13.9443 8.75616 13.9634 8.4493 14.168L5.4493 16.168C4.98978 16.4744 4.8656 17.0952 5.17196 17.5548C5.4783 18.0142 6.09918 18.1384 6.5587 17.832L8.004 16.8686V27C8.004 27.5522 8.45172 28 9.004 28C9.5563 28 10.004 27.5522 10.004 27V15ZM19.0097 14C17.1399 14 15.8155 14.8734 15.0181 16.2584C14.2651 17.5666 14.0097 19.2732 14.0097 21C14.0097 22.7268 14.2651 24.4334 15.0181 25.7416C15.8155 27.1266 17.1399 28 19.0097 28C20.8797 28 22.2041 27.1266 23.0015 25.7416C23.7545 24.4334 24.0097 22.7268 24.0097 21C24.0097 19.2732 23.7545 17.5666 23.0015 16.2584C22.2041 14.8734 20.8797 14 19.0097 14ZM16.0097 21C16.0097 19.413 16.2545 18.1196 16.7515 17.2562C17.2041 16.4698 17.8797 16 19.0097 16C20.1399 16 20.8155 16.4698 21.2681 17.2562C21.7651 18.1196 22.0097 19.413 22.0097 21C22.0097 22.587 21.7651 23.8804 21.2681 24.7438C20.8155 25.5302 20.1399 26 19.0097 26C17.8797 26 17.2041 25.5302 16.7515 24.7438C16.2545 23.8804 16.0097 22.587 16.0097 21Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.previous-10-icon {
  height: 29px;
  width: 29px;
}
</style>
