<template>
  <svg
    role="img"
    aria-label="user icon"
    class="user-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>User</title>
    <path
      d="M21.0306 13.6519C20.9519 13.5738 20.8587 13.5119 20.7561 13.4699C20.6535 13.4278 20.5437 13.4064 20.4328 13.4067C20.3219 13.4071 20.2122 13.4294 20.1099 13.4721C20.0077 13.5149 19.9148 13.5774 19.8367 13.6561C19.7586 13.7348 19.6967 13.8281 19.6547 13.9306C19.6126 14.0332 19.5912 14.1431 19.5916 14.2539C19.5919 14.3648 19.6142 14.4745 19.657 14.5768C19.6997 14.6791 19.7623 14.7719 19.8409 14.85C20.5549 15.5564 21.1216 16.3974 21.5083 17.3243C21.895 18.2513 22.094 19.2457 22.0937 20.25C22.0937 21.2794 19.1322 22.7813 14.5 22.7813C9.8678 22.7813 6.90624 21.2794 6.90624 20.25C6.9049 19.2523 7.10019 18.2641 7.48095 17.3419C7.86171 16.4196 8.42047 15.5815 9.1253 14.8753C9.28245 14.7172 9.37066 14.5034 9.37066 14.2805C9.37066 14.0576 9.28245 13.8437 9.1253 13.6856C8.96722 13.5285 8.75337 13.4403 8.53046 13.4403C8.30755 13.4403 8.0937 13.5285 7.93562 13.6856C7.07086 14.5456 6.38542 15.5686 5.91905 16.6954C5.45267 17.8223 5.21463 19.0305 5.21874 20.25C5.21874 22.9922 10.0028 24.4688 14.5 24.4688C18.9972 24.4688 23.7812 22.9922 23.7812 20.25C23.7845 19.0226 23.5429 17.8068 23.0706 16.6739C22.5983 15.5409 21.9048 14.5136 21.0306 13.6519Z"
      fill="currentColor"
    />
    <path
      d="M14.5 14.3438C15.6681 14.3438 16.8101 13.9974 17.7813 13.3484C18.7526 12.6994 19.5096 11.777 19.9567 10.6977C20.4037 9.6185 20.5207 8.43095 20.2928 7.28525C20.0649 6.13955 19.5024 5.08716 18.6763 4.26115C17.8503 3.43515 16.798 2.87263 15.6523 2.64474C14.5066 2.41685 13.319 2.53381 12.2398 2.98084C11.1605 3.42787 10.2381 4.18489 9.58913 5.15617C8.94015 6.12744 8.59375 7.26936 8.59375 8.4375C8.59375 10.0039 9.21601 11.5062 10.3237 12.6139C11.4313 13.7215 12.9336 14.3438 14.5 14.3438ZM14.5 4.21875C15.3344 4.21875 16.15 4.46618 16.8438 4.92974C17.5376 5.3933 18.0783 6.05218 18.3976 6.82306C18.7169 7.59393 18.8005 8.44218 18.6377 9.26054C18.4749 10.0789 18.0731 10.8306 17.4831 11.4206C16.8931 12.0106 16.1414 12.4124 15.323 12.5752C14.5047 12.738 13.6564 12.6544 12.8856 12.3351C12.1147 12.0158 11.4558 11.4751 10.9922 10.7813C10.5287 10.0875 10.2812 9.27189 10.2812 8.4375C10.2812 7.31862 10.7257 6.24557 11.5169 5.4544C12.3081 4.66323 13.3811 4.21875 14.5 4.21875Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.user-icon {
  height: 28px;
  width: 28px;
}
</style>
