
import * as wagtailRuntime$vmw5qe5ioL from '/code/node_modules/@nuxt/image/dist/runtime/providers/wagtail'
import * as ipxRuntime$5JseCjCUhN from '/code/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 390,
    "sm": 640,
    "md": 767,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1920
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['wagtail']: { provider: wagtailRuntime$vmw5qe5ioL, defaults: {"baseURL":"https://cms.prod.nypr.digital/images/","screens":{"xs":390,"sm":640,"md":767,"lg":1024,"xl":1280,"xxl":1536,"2xl":1920}} },
  ['ipx']: { provider: ipxRuntime$5JseCjCUhN, defaults: {} }
}
        