<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    :aria-label="props.active ? 'Show Followed' : 'Show Unfollowed'"
    class="follow-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Follow</title>

    <g v-if="!props.active">
      <path
        d="M17.26 16.27L14.37 14.82C13.43 14.35 12.85 13.41 12.85 12.36V8.60004H14.35V12.36C14.35 12.84 14.61 13.26 15.04 13.48L17.93 14.93L17.26 16.27Z"
        fill="currentColor"
      />
      <path
        d="M14.91 21.74C14.5 21.8 14.09 21.85 13.67 21.85C9.16 21.85 5.5 18.18 5.5 13.68C5.5 9.18 9.17 5.51 13.67 5.51C18.17 5.51 21.84 9.18 21.84 13.68C21.84 13.84 21.83 13.99 21.82 14.14C22.34 14.21 22.83 14.34 23.3 14.52C23.32 14.24 23.34 13.96 23.34 13.67C23.34 8.34 19 4 13.67 4C8.34 4 4 8.34 4 13.67C4 19 8.34 23.34 13.67 23.34C14.28 23.34 14.87 23.28 15.44 23.17C15.21 22.72 15.03 22.24 14.91 21.73V21.74Z"
        fill="currentColor"
      />
      <path
        d="M24.89 19.57H21.74V16.42H20.24V19.57H17.09V21.07H20.24V24.22H21.74V21.07H24.89V19.57Z"
        fill="currentColor"
      />
    </g>
    <g v-else>
      <path
        d="M14.16 19.48C14.16 17.76 14.85 16.21 15.98 15.08L13.78 13.98C12.84 13.51 12.26 12.57 12.26 11.52V7.75997H13.76V11.52C13.76 12 14.02 12.43 14.45 12.64L17.31 14.07C18.23 13.54 19.28 13.24 20.41 13.24C21.22 13.24 22 13.4 22.71 13.68C22.73 13.4 22.75 13.12 22.75 12.83C22.75 7.49997 18.41 3.15997 13.08 3.15997C7.75 3.15997 3.41 7.49997 3.41 12.83C3.41 18.16 7.75 22.5 13.08 22.5C13.69 22.5 14.28 22.44 14.85 22.33C14.41 21.47 14.16 20.51 14.16 19.48Z"
        fill="currentColor"
      />
      <path d="M24.33 18.73H16.53V20.23H24.33V18.73Z" fill="currentColor" />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.follow-icon path {
  height: 28px;
  width: 28px;
}
</style>
