<template>
  <svg
    role="img"
    aria-label="user icon"
    class="delete-user-icon o-icon"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Delete User</title>
    <path d="M87.08 55.6099H63.89C62.51 55.6099 61.39 54.4899 61.39 53.1099C61.39 51.7299 62.51 50.6099 63.89 50.6099H87.08C88.46 50.6099 89.58 51.7299 89.58 53.1099C89.58 54.4899 88.46 55.6099 87.08 55.6099Z" fill="currentColor" />
    <path d="M45.39 46.0798C35.66 46.0798 27.75 38.1598 27.75 28.4398C27.75 18.7198 35.67 10.7998 45.39 10.7998C55.11 10.7998 63.03 18.7198 63.03 28.4398C63.03 38.1598 55.11 46.0798 45.39 46.0798ZM45.39 15.7998C38.42 15.7998 32.75 21.4698 32.75 28.4398C32.75 35.4098 38.42 41.0798 45.39 41.0798C52.36 41.0798 58.03 35.4098 58.03 28.4398C58.03 21.4698 52.36 15.7998 45.39 15.7998Z" fill="currentColor" />
    <path d="M45.3 84.2501C34.29 84.2501 23.08 82.0301 16.99 77.2201C14.43 75.2001 13.22 71.5001 13.59 66.8101C14.2 58.9001 19.24 48.4101 27.68 43.6501C28.88 42.9701 30.41 43.4001 31.09 44.6001C31.77 45.8001 31.34 47.3301 30.14 48.0101C23.3 51.8701 19.08 60.7801 18.58 67.2001C18.36 70.0901 18.92 72.3701 20.1 73.3001C27.91 79.4701 49.85 81.2101 63.57 76.7601C68.93 75.0201 72.31 72.5401 73.08 69.7801C73.45 68.4501 74.83 67.6801 76.16 68.0501C77.49 68.4201 78.26 69.8001 77.89 71.1301C76.63 75.6201 72.21 79.2101 65.11 81.5101C59.59 83.3001 52.49 84.2501 45.31 84.2501H45.3Z" fill="currentColor" />
</svg> 
</template>

<style lang="scss" scoped>
.delete-user-icon {
  height: 28px;
  width: 28px;
  opacity: 0.7;
}
</style>
