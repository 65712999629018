<template>
  <svg
    role="img"
    aria-label="pause icon"
    class="pause-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 10"
  >
    <title>Pause</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.49999 0H0V9.99996H2.49999V0ZM8.33328 0H5.8333V9.99996H8.33328V0Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.pause-icon {
  height: 11px;
  width: 13px;
}
</style>
