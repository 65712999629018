<template>
  <svg
    role="img"
    aria-label="share icon"
    class="share-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Share</title>
    <path
      d="M21.33 14.33C20.92 14.33 20.58 14.67 20.58 15.08V21.58C20.58 21.95 20.43 22.32 20.17 22.58C19.91 22.84 19.54 23 19.17 23H7.25C6.88 23 6.51 22.85 6.25 22.58C5.99 22.31 5.83 21.95 5.83 21.58V9.66C5.83 9.28 5.98 8.93 6.25 8.66C6.51 8.4 6.88 8.25 7.25 8.25H13.75C14.16 8.25 14.5 7.91 14.5 7.5C14.5 7.09 14.16 6.75 13.75 6.75H7.25C6.48 6.75 5.73 7.06 5.19 7.6C4.64 8.15 4.34 8.88 4.34 9.66V21.58C4.34 22.35 4.65 23.1 5.19 23.64C5.73 24.18 6.48 24.49 7.25 24.49H19.17C19.94 24.49 20.69 24.18 21.23 23.64C21.77 23.1 22.08 22.35 22.08 21.58V15.08C22.08 14.67 21.74 14.33 21.33 14.33Z"
      fill="currentColor"
    />
    <path
      d="M25.27 3.95999C25.19 3.77999 25.05 3.62999 24.86 3.54999C24.77 3.50999 24.67 3.48999 24.57 3.48999H18.07C17.66 3.48999 17.32 3.82999 17.32 4.23999C17.32 4.64999 17.66 4.98999 18.07 4.98999H22.76L12.12 15.63C11.83 15.92 11.83 16.4 12.12 16.69C12.27 16.84 12.46 16.91 12.65 16.91C12.84 16.91 13.03 16.84 13.18 16.69L23.82 6.04999V10.74C23.82 11.15 24.16 11.49 24.57 11.49C24.98 11.49 25.32 11.15 25.32 10.74V4.23999C25.32 4.13999 25.3 4.04999 25.26 3.94999L25.27 3.95999Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.share-icon {
  height: 28px;
  width: 28px;
}
</style>
