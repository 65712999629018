<template>
  <svg
    role="img"
    aria-label="next-10 icon"
    class="next-10-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29 29"
  >
    <title>Next 10</title>

    <path
      d="M28.4199 1C28.4199 0.44772 27.9721 0 27.4199 0C26.8677 0 26.4199 0.44772 26.4199 1V5.4049C24.5957 3.33976 22.2555 1.7665 19.6139 0.86214C15.9415 -0.39514 11.9371 -0.27382 8.34744 1.20342C4.75782 2.68066 1.82798 5.413 0.104223 8.89098C-0.141017 9.38582 0.0613034 9.9858 0.556163 10.231C1.051 10.4763 1.65096 10.274 1.89622 9.77912C3.4044 6.73608 5.96784 4.34544 9.10856 3.05292C12.2493 1.76042 15.7529 1.65428 18.9661 2.75432C21.7927 3.722 24.2247 5.56496 25.9191 8H19.4199C18.8677 8 18.4199 8.44772 18.4199 9C18.4199 9.55228 18.8677 10 19.4199 10H27.4199C27.9721 10 28.4199 9.55228 28.4199 9V1ZM15.4341 16.2584C16.2315 14.8734 17.5559 14 19.4257 14C21.2957 14 22.6201 14.8734 23.4175 16.2584C24.1705 17.5666 24.4257 19.2732 24.4257 21C24.4257 22.7268 24.1705 24.4334 23.4175 25.7416C22.6201 27.1266 21.2957 28 19.4257 28C17.5559 28 16.2315 27.1266 15.4341 25.7416C14.6811 24.4334 14.4257 22.7268 14.4257 21C14.4257 19.2732 14.6811 17.5666 15.4341 16.2584ZM17.1675 17.2562C16.6705 18.1196 16.4257 19.413 16.4257 21C16.4257 22.587 16.6705 23.8804 17.1675 24.7438C17.6201 25.5302 18.2957 26 19.4257 26C20.5559 26 21.2315 25.5302 21.6841 24.7438C22.1811 23.8804 22.4257 22.587 22.4257 21C22.4257 19.413 22.1811 18.1196 21.6841 17.2562C21.2315 16.4698 20.5559 16 19.4257 16C18.2957 16 17.6201 16.4698 17.1675 17.2562ZM10.42 15C10.42 14.6312 10.217 14.2924 9.89188 14.1184C9.56672 13.9443 9.17218 13.9634 8.86532 14.168L5.86532 16.168C5.4058 16.4744 5.28162 17.0952 5.58798 17.5548C5.89432 18.0142 6.5152 18.1384 6.97472 17.832L8.42002 16.8686V27C8.42002 27.5522 8.86774 28 9.42002 28C9.97232 28 10.42 27.5522 10.42 27V15Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss" scoped>
.next-10-icon {
  height: 29px;
  width: 29px;
}
</style>
