<script setup lang="ts">
const props = defineProps({
  animate: {
    type: Boolean,
    default: true,
  },
})

const { $gsap } = useNuxtApp()
const svgRef = ref<SVGElement | null>(null)
onMounted(() => {
  if (props.animate) {
    $gsap.from(svgRef.value, {
      opacity: 0,
      y: -20,
      duration: 1,
      ease: "bounce.out",
    })
  }
})
</script>

<template>
  <svg
    ref="svgRef"
    role="img"
    aria-label="downloadedsmall icon"
    class="downloadedsmall-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
  >
    <title>Downloaded Small</title>
    <path
      d="M7.99999 0.666656C6.5496 0.666656 5.13177 1.09675 3.92581 1.90255C2.71985 2.70834 1.77992 3.85365 1.22488 5.19364C0.669834 6.53364 0.524609 8.00812 0.807568 9.43065C1.09053 10.8532 1.78896 12.1599 2.81454 13.1854C3.84013 14.211 5.1468 14.9095 6.56933 15.1924C7.99186 15.4754 9.46635 15.3301 10.8063 14.7751C12.1463 14.2201 13.2916 13.2801 14.0974 12.0742C14.9032 10.8682 15.3333 9.45039 15.3333 7.99999C15.3333 6.05507 14.5607 4.18981 13.1854 2.81454C11.8102 1.43927 9.94491 0.666656 7.99999 0.666656ZM11.8047 6.47132L7.13799 11.138C7.01297 11.263 6.84344 11.3332 6.66666 11.3332C6.48988 11.3332 6.32034 11.263 6.19533 11.138L4.19533 9.13799C4.07389 9.01225 4.00669 8.84385 4.00821 8.66905C4.00973 8.49426 4.07984 8.32705 4.20345 8.20344C4.32705 8.07984 4.49426 8.00973 4.66906 8.00821C4.84386 8.00669 5.01226 8.07388 5.13799 8.19532L6.66666 9.72399L10.862 5.52866C10.9877 5.40722 11.1561 5.34002 11.3309 5.34154C11.5057 5.34306 11.6729 5.41317 11.7965 5.53678C11.9201 5.66038 11.9903 5.82759 11.9918 6.00239C11.9933 6.17719 11.9261 6.34559 11.8047 6.47132Z"
      fill="currentColor"
      fill-opacity="0.7"
    />
  </svg>
</template>

<style lang="scss" scoped>
.downloadedsmall-icon {
  height: 16px;
  width: 16px;
}
</style>
