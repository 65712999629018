<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    :aria-label="props.active ? 'Remove from Favorites' : 'Add to Favorites'"
    class="star-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Star</title>
    <path
      v-if="!props.active"
      d="M14 5.74001L16.44 10.48C16.73 11.05 17.28 11.44 17.91 11.52L23.24 12.26L19.44 15.81C18.95 16.27 18.73 16.93 18.85 17.59L19.76 22.68L14.89 20.22C14.62 20.08 14.31 20.01 14.01 20.01C13.71 20.01 13.4 20.08 13.13 20.22L8.26 22.68L9.17 17.59C9.29 16.93 9.07 16.27 8.58 15.81L4.78 12.26L10.11 11.52C10.74 11.43 11.29 11.04 11.58 10.48L14.02 5.74001M14.02 3.01001C13.86 3.01001 13.7 3.09001 13.61 3.26001L10.25 9.79001C10.18 9.92001 10.05 10.01 9.9 10.04L2.41 11.08C2.03 11.13 1.88 11.61 2.16 11.87L7.55 16.91C7.66 17.02 7.72 17.17 7.69 17.33L6.41 24.47C6.36 24.77 6.59 25.01 6.86 25.01C6.93 25.01 7 24.99 7.07 24.96L13.8 21.57C13.87 21.54 13.94 21.52 14.01 21.52C14.08 21.52 14.15 21.54 14.22 21.57L20.95 24.96C21.02 24.99 21.09 25.01 21.16 25.01C21.43 25.01 21.67 24.77 21.61 24.47L20.33 17.33C20.3 17.18 20.35 17.02 20.47 16.91L25.86 11.87C26.14 11.61 25.99 11.13 25.61 11.08L18.12 10.04C17.97 10.02 17.84 9.93001 17.77 9.79001L14.41 3.26001C14.32 3.09001 14.16 3.01001 14 3.01001H14.02Z"
    />
    <path
      v-else
      d="M25.85 11.86C26.13 11.6 25.98 11.12 25.6 11.07L18.11 10.03C17.96 10.01 17.83 9.92 17.76 9.78L14.4 3.25C14.31 3.08 14.15 3 13.99 3C13.83 3 13.67 3.08 13.58 3.25L10.22 9.78C10.15 9.91 10.02 10 9.87 10.03L2.38 11.07C2 11.12 1.85 11.6 2.13 11.86L7.52 16.9C7.63 17.01 7.69 17.16 7.66 17.32L6.38 24.46C6.33 24.76 6.56 25 6.83 25C6.9 25 6.97 24.98 7.04 24.95L13.77 21.56C13.84 21.53 13.91 21.51 13.98 21.51C14.05 21.51 14.12 21.53 14.19 21.56L20.92 24.95C20.99 24.98 21.06 25 21.13 25C21.4 25 21.64 24.76 21.58 24.46L20.3 17.32C20.27 17.17 20.32 17.01 20.44 16.9L25.83 11.86H25.85Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.star-icon {
  fill: var(--text-color);
  height: 52px;
}
</style>
