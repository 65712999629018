// Generated by @hypernym/nuxt-gsap
import { defineNuxtPlugin } from '#app';
import { gsap } from 'gsap';

const plugin = defineNuxtPlugin(() => {
  
  return {
    provide: {
      gsap,
      
    }
  }
})
export default plugin;