<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
</script>
<template>
  <svg
    role="img"
    aria-label="download icon"
    class="download-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
  >
    <title>Download</title>

    <g v-if="!props.active">
      <path
        d="M21.64 24.58H6.29999C4.67999 24.58 3.35999 23.26 3.35999 21.64V17.26C3.35999 16.85 3.69999 16.51 4.10999 16.51C4.51999 16.51 4.85999 16.85 4.85999 17.26V21.64C4.85999 22.43 5.50999 23.08 6.29999 23.08H21.64C22.43 23.08 23.08 22.43 23.08 21.64V17.26C23.08 16.85 23.42 16.51 23.83 16.51C24.24 16.51 24.58 16.85 24.58 17.26V21.64C24.58 23.26 23.26 24.58 21.64 24.58Z"
        fill="currentColor"
      />
      <path
        d="M19.98 11.25C19.69 10.96 19.21 10.96 18.92 11.25L14.72 15.45V4.10999C14.72 3.69999 14.38 3.35999 13.97 3.35999C13.56 3.35999 13.22 3.69999 13.22 4.10999V15.45L9.01999 11.25C8.72999 10.96 8.24999 10.96 7.95999 11.25C7.66999 11.54 7.66999 12.02 7.95999 12.31L13.44 17.79C13.51 17.86 13.59 17.91 13.68 17.95C13.77 17.99 13.87 18.01 13.97 18.01C14.07 18.01 14.17 17.99 14.26 17.95C14.35 17.91 14.43 17.86 14.5 17.79L19.98 12.31C20.27 12.02 20.27 11.54 19.98 11.25Z"
        fill="currentColor"
      />
    </g>
    <g v-else>
      <path
        d="M23.25 23.08H4.75C4.33579 23.08 4 23.4158 4 23.83C4 24.2442 4.33579 24.58 4.75 24.58H23.25C23.6642 24.58 24 24.2442 24 23.83C24 23.4158 23.6642 23.08 23.25 23.08Z"
        fill="currentColor"
      />
      <path
        d="M23.25 23.08H4.75C4.34 23.08 4 23.42 4 23.83C4 24.24 4.34 24.58 4.75 24.58H23.25C23.66 24.58 24 24.24 24 23.83C24 23.42 23.66 23.08 23.25 23.08Z"
        fill="currentColor"
      />
      <path
        d="M14 3.39001C12.32 3.39001 10.68 3.89001 9.28 4.82001C7.88 5.75001 6.79 7.08001 6.15 8.63001C5.51 10.18 5.34 11.89 5.67 13.54C6 15.19 6.81 16.7 8 17.89C9.19 19.08 10.7 19.89 12.35 20.22C14 20.55 15.71 20.38 17.26 19.74C18.81 19.1 20.14 18.01 21.07 16.61C22 15.21 22.5 13.57 22.5 11.89C22.5 9.64001 21.6 7.47001 20.01 5.88001C18.42 4.29001 16.25 3.39001 14 3.39001ZM18.41 10.12L13 15.53C12.86 15.67 12.66 15.76 12.45 15.76C12.24 15.76 12.05 15.68 11.9 15.53L9.58 13.21C9.44 13.06 9.36 12.87 9.36 12.67C9.36 12.47 9.44 12.27 9.59 12.13C9.73 11.99 9.93 11.91 10.13 11.9C10.33 11.9 10.53 11.98 10.67 12.12L12.44 13.89L17.3 9.03001C17.45 8.89001 17.64 8.81001 17.84 8.81001C18.04 8.81001 18.24 8.89001 18.38 9.04001C18.52 9.18001 18.6 9.38001 18.61 9.58001C18.62 9.78001 18.53 9.98001 18.39 10.12H18.41Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.download-icon {
  height: 28px;
  width: 28px;
}
</style>
