<template>
  <svg
    role="img"
    aria-label="wnyc logo"
    class="wnyc-logo o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1 8 22 9"
  >
    <title>wnyc logo</title>
    <path
      d="M12.422 16.735h4.857v-6.93h-4.857v6.93zm3.086-5.4h.963l-1.181 1.892v1.216h-.866V13.24l-1.181-1.905h.981l.64 1.092.644-1.092zm2.625 5.4h4.857V8h-4.857v8.735zm2.542-2.23a1.578 1.578 0 0 1-1.603-1.607v-.009c0-.905.697-1.616 1.638-1.616.635 0 1.044.266 1.32.648l-.649.502c-.178-.222-.382-.364-.68-.364-.435 0-.74.368-.74.821v.01c0 .466.305.83.74.83.325 0 .516-.151.702-.378l.649.462c-.294.404-.689.701-1.377.701zm-13.964 2.23h4.857V9.028H6.711v7.707zm3.941-2.292h-.755L8.57 12.738v1.705h-.853v-3.108h.804L9.8 12.978v-1.643h.852v3.108zm-8.598.016h.75l.622-1.79.627 1.79h.75l1.039-3.13H4.95l-.542 1.798-.6-1.807h-.741l-.6 1.807-.541-1.798h-.91l1.038 3.13zm-1.054.8h4.857v1.476H1v-1.476z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.wnyc-logo {
  fill: var(--wnyc-logo-color);
  height: auto;
}
</style>
