<script setup>
const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
})
const { $gsap } = useNuxtApp()
const thisIcon = ref(null)
const stars = ref(null)
let anim = null

onMounted(() => {
  stars.value = thisIcon.value.getElementsByClassName("stars")
  anim = $gsap.to(stars.value, {
    duration: 0.6,
    repeat: -1,
    yoyo: true,
    opacity: 0.4,
    scale: 0.9,
    transformOrigin: "0% 50%",
    ease: "power1.inOut",
    stagger: 0.3,
    paused: true,
  })
})

onUnmounted(() => {
  if (anim) {
    anim.kill()
  }
})

watchEffect(() => {
  if (props.active && stars.value) {
    anim.play()
  } else {
    if (anim) {
      anim.pause()
    }
  }
})
</script>
<template>
  <svg
    role="img"
    aria-label="sleep icon"
    class="sleep-icon o-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    ref="thisIcon"
  >
    <title>Sleep</title>

    <g v-show="!props.active">
      <path
        d="M22.7 18.95C22.44 18.58 21.98 18.4 21.53 18.49C20.92 18.62 20.28 18.69 19.64 18.69C14.68 18.69 10.64 14.68 10.64 9.73998C10.64 8.25998 11.01 6.78998 11.72 5.48998C11.94 5.08998 11.9 4.59998 11.6 4.24998C11.3 3.87998 10.78 3.74998 10.33 3.92998C6.38 5.56998 3.83 9.37998 3.83 13.63C3.83 19.43 8.58 24.14 14.41 24.14H14.43C17.63 24.14 20.62 22.72 22.64 20.24C22.95 19.87 22.97 19.33 22.69 18.94L22.7 18.95ZM9.07 9.73998C9.07 15.54 13.82 20.25 19.65 20.25C19.94 20.25 20.23 20.24 20.52 20.21C18.87 21.71 16.69 22.57 14.45 22.57H14.43C9.47 22.57 5.43 18.56 5.43 13.62C5.43 10.47 7.08 7.57998 9.77 5.96998C9.31 7.16998 9.07 8.42998 9.07 9.72998V9.73998Z"
        fill="currentColor"
      />
      <path
        d="M17.49 7.93C17.54 7.79 17.69 7.71 17.83 7.76C17.91 7.79 17.98 7.85 18 7.93L18.48 9.38C18.7 10.03 19.2 10.54 19.85 10.75L21.3 11.23C21.44 11.28 21.52 11.43 21.47 11.57C21.44 11.65 21.38 11.72 21.3 11.74L19.85 12.22C19.2 12.44 18.69 12.94 18.48 13.59L18 15.04C17.95 15.18 17.8 15.26 17.66 15.21C17.58 15.18 17.51 15.12 17.49 15.04L17.01 13.59C16.79 12.94 16.29 12.43 15.64 12.22L14.19 11.74C14.05 11.69 13.97 11.54 14.02 11.4C14.05 11.32 14.11 11.25 14.19 11.23L15.64 10.75C16.29 10.53 16.8 10.03 17.01 9.38L17.49 7.93ZM21.33 4.12C21.36 4.03 21.47 3.98 21.56 4.01C21.61 4.03 21.65 4.07 21.67 4.12L21.99 5.09C22.13 5.52 22.47 5.86 22.91 6.01L23.88 6.33C23.97 6.36 24.02 6.47 23.99 6.56C23.97 6.61 23.93 6.65 23.88 6.67L22.91 6.99C22.48 7.13 22.14 7.47 21.99 7.91L21.67 8.88C21.64 8.97 21.53 9.02 21.44 8.99C21.39 8.97 21.35 8.93 21.33 8.88L21.01 7.91C20.87 7.48 20.53 7.14 20.09 6.99L19.12 6.67C19.03 6.64 18.98 6.53 19.01 6.44C19.03 6.39 19.07 6.35 19.12 6.33L20.09 6.01C20.52 5.87 20.86 5.53 21.01 5.09L21.33 4.12Z"
        fill="currentColor"
      />
    </g>
    <g v-show="props.active">
      <path
        d="M22.7 18.95C22.44 18.58 21.98 18.4 21.53 18.49C20.92 18.62 20.28 18.69 19.64 18.69C14.68 18.69 10.64 14.68 10.64 9.73998C10.64 8.25998 11.01 6.78998 11.72 5.48998C11.94 5.08998 11.9 4.59998 11.6 4.24998C11.3 3.87998 10.78 3.74998 10.33 3.92998C6.38 5.56998 3.83 9.37998 3.83 13.63C3.83 19.43 8.58 24.14 14.41 24.14H14.43C17.63 24.14 20.62 22.72 22.64 20.24C22.95 19.87 22.97 19.33 22.69 18.94L22.7 18.95Z"
        fill="currentColor"
      />
      <path
        class="stars"
        d="M17.49 4.07 21.67 4.12L21.99 5.09C22.13 5.52 22.47 5.86 22.91 6.01L23.88 6.33C23.97 6.36 24.02 6.47 23.99 6.56C23.97 6.61 23.93 6.65 23.88 6.67L22.91 6.99C22.48 7.13 22.14 7.47 21.99 7.91L21.67 8.88C21.64 8.97 21.53 9.02 21.44 8.99C21.39 8.97 21.35 8.93 21.33 8.88L21.01 7.91C20.87 7.48 20.53 7.14 20.09 6.99L19.12 6.67C19.03 6.64 18.98 6.53 19.01 6.44C19.03 6.39 19.07 6.35 19.12 6.33L20.09 6.01C20.52 5.87 20.86 5.53 21.01 5.09L21.33 4.12Z"
        fill="currentColor"
      />

      <path
        class="stars"
        d="M17.49 7.93C17.54 7.79 17.69 7.71 17.83 7.76C17.91 7.79 17.98 7.85 18 7.93L18.48 9.38C18.7 10.03 19.2 10.54 19.85 10.75L21.3 11.23C21.44 11.28 21.52 11.43 21.47 11.57C21.44 11.65 21.38 11.72 21.3 11.74L19.85 12.22C19.2 12.44 18.69 12.94 18.48 13.59L18 15.04C17.95 15.18 17.8 15.26 17.66 15.21C17.58 15.18 17.51 15.12 17.49 15.04L17.01 13.59C16.79 12.94 16.29 12.43 15.64 12.22L14.19 11.74C14.05 11.69 13.97 11.54 14.02 11.4C14.05 11.32 14.11 11.25 14.19 11.23L15.64 10.75C16.29 10.53 16.8 10.03 17.01 9.38L17.49 7.93Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.sleep-icon {
  height: 28px;
  width: 28px;
}
</style>
